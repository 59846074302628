import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const SuggestedProduct = () => {
    const products = [
        {
            id: '1',
            name: 'Realme C21 (Cross Black, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/kn7sdjk0/mobile/q/j/x/c21-rmx3201-realme-original-imagfxfwbszrxkvu.jpeg?q=70',
            price: 120,
        },
        {
            id: '2',
            name: 'Realme C21 (Cross Blue, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/knm2s280/mobile/j/x/c/hot-10-play-x688b-infinix-original-imag29gxqzuxkmnk.jpeg?q=70',
            price: 120,
        },
        {
            id: '3',
            name: 'Realme C21 (Cross Black, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/kn7sdjk0/mobile/g/r/g/c21-rmx3201-realme-original-imagfxfwn9aryyda.jpeg?q=70',
            price: 120,
        },
        {
            id: '4',
            name: 'Realme C21 (Cross Black, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/knm2s280/mobile/v/l/u/hot-10-play-x688b-infinix-original-imag29hfaedkgdfe.jpeg?q=70',
            price: 120,
        },
        {
            id: '5',
            name: 'Realme C21 (Cross Black, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/knrsjgw0/mobile/f/o/w/8-5g-rmx3241-realme-original-imag2d8eksc2szzy.jpeg?q=70',
            price: 120,
        },
        {
            id: '6',
            name: 'Realme C21 (Cross Black, 64 GB)',
            image: 'https://rukminim1.flixcart.com/image/416/416/kd69z0w0/mobile/a/n/g/mi-redmi-note-9-b086982zkf-original-imafu4qf8gfcutde.jpeg?q=70',
            price: 120,
        },
    ];

    return (
        <Container className="text-center" fluid>
            <div className="section-title text-center mb-55">
                <h2>YOU MAY ALSO LIKE</h2>
                <p>Some of our exclusive collection you may like</p>
            </div>

            <Row>
                {products.map((product) => (
                    <Col className="p-1" key={product.id} xl={2} lg={3} md={4} sm={6} xs={12}>
                        <Link to={`/productdetails/${product.id}`}>
                            <Card className="image-box card">
                                <img
                                    className="center product-image"
                                    src={product.image}
                                    alt={product.name}
                                />
                                <Card.Body>
                                    <p className="product-name-on-card">{product.name}</p>
                                    <p className="product-price-on-card">Price: ${product.price}</p>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default SuggestedProduct;