import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import {Card, Col, Container, Row} from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import local images dynamically
const productImages = Array.from({length: 14}, (_, index) =>
    require(`../../assets/image/Product(${index + 1}).jpg`)
);

class NewArrival extends Component {

    constructor(props) {
        super(props);
        this.slider = React.createRef();
    }

    next = () => {
        this.slider.current.slickNext();
    };

    previous = () => {
        this.slider.current.slickPrev();
    };


    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 800,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            slidesToScroll: 3,
            initialSlide: 0,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        // Mock product data
        const products = [
            {
                id: 1,
                name: 'Ariel Matic Front Load Detergent Powder',
                price: '$15',
                image: productImages[0],
            },
            {
                id: 2,
                name: 'Surf Excel Matic Liquid Detergent',
                price: '$12',
                image: productImages[1],
            },
            {
                id: 3,
                name: 'Tide Plus Double Power Detergent Powder',
                price: '$10',
                image: productImages[2],
            },
            {
                id: 4,
                name: 'Rin Advanced Detergent Powder',
                price: '$8',
                image: productImages[3],
            },
            {
                id: 5,
                name: 'Persil ProClean Liquid Laundry Detergent',
                price: '$18',
                image: productImages[4],
            },
            {
                id: 6,
                name: 'Downy Liquid Fabric Softener and Detergent',
                price: '$14',
                image: productImages[5],
            },
            {
                id: 7,
                name: 'OMO Active Clean Laundry Detergent Powder',
                price: '$9',
                image: productImages[6],
            },
            {
                id: 8,
                name: 'Gain Liquid Laundry Detergent Original Scent',
                price: '$11',
                image: productImages[7],
            },
            {
                id: 9,
                name: 'Ariel Complete Detergent Washing Powder',
                price: '$13',
                image: productImages[8],
            },
            {
                id: 10,
                name: 'Tide Free and Gentle Liquid Laundry Detergent',
                price: '$16',
                image: productImages[9],
            },
            {
                id: 11,
                name: 'Seventh Generation Liquid Laundry Detergent',
                price: '$19',
                image: productImages[10],
            },
            {
                id: 12,
                name: 'Woolite Delicates Hypoallergenic Liquid Detergent',
                price: '$13',
                image: productImages[11],
            },
            {
                id: 13,
                name: 'Persil Detergent Pods - Stain Fighter',
                price: '$20',
                image: productImages[12],
            },
            {
                id: 14,
                name: 'Sunlight Detergent Powder Lemon Fresh',
                price: '$9',
                image: productImages[13],
            },
        ];


        return (
            <Fragment>
                <Container className="text-center" fluid={true}>
                    <div className="section-title text-center mb-55">
                        <h2>
                            TEST PRODUCT COLLECTION&nbsp;
                            <button className="btn btn-sm ml-2 site-btn" onClick={this.previous} aria-label="Previous">
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                            </button>
                            &nbsp;
                            <button className="btn btn-sm ml-2 site-btn" onClick={this.next} aria-label="Next">
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                            &nbsp;
                        </h2>
                        <p>Some Of Our Exclusive Collection, You May Like</p>
                    </div>

                    <Row>
                        <div className="slider-container">
                            <Slider ref={this.slider} {...settings}>
                                {products.map((product) => (
                                    <div key={product.id}>
                                        <Card className="image-box card">
                                            <img
                                                className="center"
                                                src={product.image}
                                                alt={product.name}
                                                loading="lazy"
                                            />
                                            <Card.Body>
                                                <p className="product-name-on-card">{product.name}</p>
                                                <p className="product-price-on-card">Price: {product.price}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default NewArrival;