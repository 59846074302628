import React, {Component, Fragment} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'

export class Contact extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white mt-2" md={12} lg={12} sm={12} xs={12}>

                            <Row className="text-center">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form className="onboardForm">
                                        <h4 className="section-title-login">CONTACT WITH US </h4>
                                        <h6 className="section-sub-title">Please Contact With Us </h6>
                                        <input className="form-control m-2" type="text"
                                               placeholder="Enter Mobile Number"/>

                                        <input className="form-control m-2" type="email" placeholder="Enter Email"/>

                                        <input className="form-control m-2" type="text"
                                               placeholder="Enter Your Message"/>
                                        <Button className="btn btn-block m-2 site-btn-login"> Send </Button>

                                    </Form>

                                </Col>

                                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                                    <br></br><br></br>
                                    <p className="section-title-contact">
                                        Next to Salim Karwan Square, WATAN MARKET Plaza. Marketing Office. <br/>
                                        Email: Support@dig.af
                                    </p>

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6571.582099706483!2d69.16114299999998!3d34.55884549999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfa!2s!4v1732962294180!5m2!1sfa!2s"
                                        width="550" height="450" styles={{border: '0'}} allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default Contact
