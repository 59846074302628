// menuData.js
const menuData = [
    {
        title: 'Electronics',
        icon: 'https://image.flaticon.com/icons/png/128/739/739249.png',
        items: [
            { name: 'Mobile Phones', link: '/mobile-phones' },
            { name: 'Laptops', link: '/laptops' },
        ],
    },
    {
        title: 'Home Appliances',
        icon: 'https://image.flaticon.com/icons/png/128/739/739249.png',
        items: [
            { name: 'Refrigerators', link: '/refrigerators' },
            { name: 'Washing Machines', link: '/washing-machines' },
        ],
    },
];

export default menuData;