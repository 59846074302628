import React, { useState } from 'react';
import menuData from "./menuData";

const MegaMenuMobile = () => {
    // State to manage active accordions
    const [activeIndexes, setActiveIndexes] = useState([]);

    const toggleAccordion = (index) => {
        setActiveIndexes((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Remove index if already open
                : [...prevIndexes, index] // Add index if closed
        );
    };

    return (
        <div className="accordionMenuDivMobile">
            <div className="accordionMenuDivInsideMobile">
                {menuData.map((category, index) => (
                    <div key={index}>
                        <button
                            className={`accordionMobile ${activeIndexes.includes(index) ? 'active' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            <img
                                className="accordionMenuIconMobile"
                                src={category.icon}
                                alt={`${category.title} Icon`}
                            />
                            &nbsp; {category.title}
                        </button>
                        <div
                            className="panelMobile"
                            style={{
                                maxHeight: activeIndexes.includes(index) ? 'fit-content' : '0',
                                overflow: 'hidden',
                            }}
                        >
                            <ul>
                                {category.items.map((item, idx) => (
                                    <li key={idx}>
                                        <a href={item.link} className="accordionItemMobile">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MegaMenuMobile;