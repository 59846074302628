import React, {Component} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Import local images dynamically
const homeSlider = Array.from({length: 3}, (_, index) =>
    require(`../../assets/image/Slider(${index + 1}).jpg`)
);

class HomeSlider extends Component {
    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
        };

        // Mock product data
        const sliders = [
            {
                id: 1,
                image: homeSlider[0],
            },
            {
                id: 2,
                image: homeSlider[1],
            },
            {
                id: 3,
                image: homeSlider[2],
            },


        ];
        return (<>
            <div className="slider-container">
                <Slider ref={this.slider} {...settings}>
                    {sliders.map((slider) => (
                        <div key={slider.id} >
                            <img
                                className="slider-img"
                                src={slider.image}
                                alt={slider.name}
                                loading="lazy"
                            />
                        </div>

                    ))}
                </Slider>
            </div>
        </>)
    }
}

export default HomeSlider;