import React, {Component, Fragment} from "react";
import {Container, Row, Col, Card} from "react-bootstrap";

class Categories extends Component {
    render() {
        const categories = [
            {
                id: 1,
                name: "Top Offers",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/f15c02bfeb02d15d.png?q=100"
            },
            {
                id: 2,
                name: "Grocery",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/29327f40e9c4d26b.png?q=100"
            },
            {
                id: 3,
                name: "Mobiles",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/22fddf3c7da4c4f4.png?q=100"
            },
            {
                id: 4,
                name: "Fashion",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/82b3ca5fb2301045.png?q=100"
            },
            {
                id: 5,
                name: "Electronics",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/69c6589653afdb9a.png?q=100"
            },
            {
                id: 6,
                name: "Home",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/ab7e2b022a4587dd.jpg?q=100"
            },
            {
                id: 7,
                name: "Appliances",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/0ff199d1bd27eb98.png?q=100"
            },
            {
                id: 8,
                name: "Beauty",
                imgSrc: "https://rukminim1.flixcart.com/flap/128/128/image/dff3f7adcf3a90c6.png?q=100"
            },
        ];

        return (
            <Fragment>
                <Container className="text-center" fluid>
                    <div className="section-title text-center mb-55">
                        <h2>TEST CATEGORIES</h2>
                        <p>Some Of Our Exclusive Collection, You May Like</p>
                    </div>
                    <Row>
                        {categories.map((category) => (
                            <Col key={category.id} className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                                <Card className="h-100 w-100 text-center">
                                    <Card.Body>
                                        <img
                                            className="center"
                                            src={category.imgSrc}
                                            alt={`Category image for ${category.name}`}
                                        />
                                        <h5 className="category-name">{category.name}</h5>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Categories;
