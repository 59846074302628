import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Product1 from "../../assets/image/product/product1.png";
import Product2 from "../../assets/image/product/product2.png";
import Product3 from "../../assets/image/product/product3.png";
import Product4 from "../../assets/image/product/product4.png";

const ImageGallery = ({ mainImage, thumbnails }) => (
    <Col className="p-3" md={6} lg={6} sm={12} xs={12}>
        <img className="w-100" src={mainImage} alt="Main product" />
        <Container className="my-3" fluid={true}>
            <Row>
                {thumbnails.map((thumbnail, index) => (
                    <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3} key={index}>
                        <img className="w-100" src={thumbnail} alt={`Thumbnail ${index + 1}`} />
                    </Col>
                ))}
            </Row>
        </Container>
    </Col>
);

const ProductInfo = ({ name, description, priceDetails, colors, sizes }) => (
    <Col className="p-3" md={6} lg={6} sm={12} xs={12}>
        <h5 className="Product-Name">{name}</h5>
        <h6 className="section-sub-title">{description}</h6>
        <div className="input-group">
            {priceDetails.map((price, index) => (
                <div className="Product-price-card d-inline" key={index}>
                    {price}
                </div>
            ))}
        </div>
        <OptionsSection title="Choose Color" options={colors} />
        <OptionsSection title="Choose Size" options={sizes} />
        <h6 className="mt-2">Quantity</h6>
        <input className="form-control text-center w-50" type="number" aria-label="Quantity" />
        <div className="input-group mt-3">
            <Button className="btn site-btn m-1">
                <i className="fa fa-shopping-cart"></i> Add To Cart
            </Button>
            <Button className="btn btn-primary m-1">
                <i className="fa fa-car"></i> Order Now
            </Button>
            <Button className="btn btn-primary m-1">
                <i className="fa fa-heart"></i> Favourite
            </Button>
        </div>
    </Col>
);

const OptionsSection = ({ title, options }) => (
    <>
        <h6 className="mt-2">{title}</h6>
        <div className="input-group">
            {options.map((option, index) => (
                <div className="form-check mx-1" key={index}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name={title.toLowerCase().replace(/\s+/g, "-")}
                        id={`${title}-${index}`}
                        value={option}
                    />
                    <label className="form-check-label" htmlFor={`${title}-${index}`}>
                        {option}
                    </label>
                </div>
            ))}
        </div>
    </>
);

const DetailsSection = ({ details }) => (
    <Col md={6} lg={6} sm={12} xs={12}>
        <h6 className="mt-2">DETAILS</h6>
        {details.map((detail, index) => (
            <p key={index}>{detail}</p>
        ))}
    </Col>
);

const ReviewsSection = ({ reviews }) => (
    <Col md={6} lg={6} sm={12} xs={12}>
        <h6 className="mt-2">REVIEWS</h6>
        {reviews.map((review, index) => (
            <div key={index}>
                <p className="p-0 m-0">
                    <span className="Review-Title">{review.reviewer}</span>{" "}
                    <span className="text-success">
            {Array(review.rating)
                .fill()
                .map((_, i) => (
                    <i className="fa fa-star" key={i}></i>
                ))}
          </span>
                </p>
                <p>{review.comment}</p>
            </div>
        ))}
    </Col>
);

const ProductDetails = () => {
    const productData = {
        name: "ASUS TUF A15 FA506IU Ryzen 7 4800H GTX",
        description: "Some Of Our Exclusive Collection, You May Like",
        priceDetails: ["Reguler Price 200", "50% Discount", "New Price 100"],
        colors: ["Black", "Green", "Red"],
        sizes: ["X", "XX", "XXXX"],
        details: [
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh...",
            "Ut wisi enim ad minim veniam, quis nostrud exerci tation...",
        ],
        reviews: [
            { reviewer: "Kazi Ariyan", rating: 4, comment: "Amazing product!" },
            { reviewer: "John Doe", rating: 5, comment: "Exceeded expectations." },
        ],
        mainImage: Product1,
        thumbnails: [Product1, Product2, Product3, Product4],
    };

    return (
        <Container fluid className="BetweenTwoSection">
            <Row className="p-2">
                <Col className="shadow-sm bg-white pb-3 mt-4" md={12} lg={12} sm={12} xs={12}>
                    <Row>
                        <ImageGallery mainImage={productData.mainImage} thumbnails={productData.thumbnails} />
                        <ProductInfo
                            name={productData.name}
                            description={productData.description}
                            priceDetails={productData.priceDetails}
                            colors={productData.colors}
                            sizes={productData.sizes}
                        />
                    </Row>
                    <Row>
                        <DetailsSection details={productData.details} />
                        <ReviewsSection reviews={productData.reviews} />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

ImageGallery.propTypes = {
    mainImage: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ProductInfo.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    priceDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

OptionsSection.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DetailsSection.propTypes = {
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ReviewsSection.propTypes = {
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            reviewer: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            comment: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ProductDetails;