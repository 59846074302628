import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';

class Collection extends Component {
    render() {
        const products = [
            {
                id: 1,
                name: "Striped Men Hooded Neck Red",
                price: "$120",
                image: "https://rukminim1.flixcart.com/image/800/960/kf1fo280hlty2aw-0/t-shirt/w/s/e/-original-imafdfvvr8hqdu65.jpeg?q=50",
            },
            {
                id: 2,
                name: "Striped Men Round Neck Maroon, Grey",
                price: "$120",
                image: "https://rukminim1.flixcart.com/image/800/960/keykscw0-0/t-shirt/l/d/q/3xl-bmrgyrnful-z12-blive-original-imafvgzkyjghf7ba.jpeg?q=50",
            },
            {
                id: 3,
                name: "Color Block Men Round Neck Grey",
                price: "$120",
                image: "https://rukminim1.flixcart.com/image/800/960/jt4olu80/t-shirt/v/7/v/xl-t-shirt-0068-eg-original-imafejrfpzjkxvkq.jpeg?q=50",
            },
            {
                id: 4,
                name: "Printed Men Hooded Neck Red T-Shirt",
                price: "$120",
                image: "https://rukminim1.flixcart.com/image/800/960/kljrvrk0/t-shirt/q/r/0/l-trdhdful-d32-tripr-original-imagynnpg2fh62ht.jpeg?q=50",
            },
            // Add more products as needed
        ];

        return (
            <Fragment>
                <Container className="text-center" fluid={true}>
                    <div className="section-title text-center mb-55">
                        <h2>TEST PRODUCT COLLECTION</h2>
                        <p>Some Of Our Exclusive Collection, You May Like</p>
                    </div>

                    <Row>
                        {products.map((product) => (
                            <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6} key={product.id}>
                                <Card className="image-box card w-100">
                                    <img
                                        className="center img-fluid"
                                        src={product.image}
                                        alt={product.name}
                                    />
                                    <Card.Body>
                                        <p className="product-name-on-card">{product.name}</p>
                                        <p className="product-price-on-card">Price: {product.price}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Collection;
