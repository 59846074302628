import React, { useState } from 'react';

const AccordionItem = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button
                className={`accordion ${isOpen ? 'active' : ''}`}
                onClick={toggleAccordion}
                aria-expanded={isOpen}
            >
                <img
                    className="accordionMenuIcon"
                    src="https://image.flaticon.com/icons/png/128/739/739249.png"
                    alt="Menu Icon"
                />
                &nbsp; {title}
            </button>
            <div
                className="panel"
                style={{
                    maxHeight: isOpen ? 'fit-content' : '0',
                    overflow: 'hidden',
                }}
            >
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>
                            <a href="#" className="accordionItem">
                                {item}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const MegaMenuAll = () => {
    const menuData = [
        {
            title: "Men's Clothing",
            items: ["Men's T-shirt 1", "Men's T-shirt 2"],
        },
        {
            title: "Women's Clothing",
            items: ["Women's Dress 1", "Women's Dress 2"],
        },
        {
            title: "Kids' Clothing",
            items: ["Kids' Shirt 1", "Kids' Shirt 2"],
        },
    ];

    return (
        <div className="accordionMenuDivAll">
            <div className="accordionMenuDivInsideAll">
                {menuData.map((menu, index) => (
                    <AccordionItem key={index} title={menu.title} items={menu.items} />
                ))}
            </div>
        </div>
    );
};

export default MegaMenuAll;